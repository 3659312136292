import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { StaticImage } from "gatsby-plugin-image";

const Styledetails = () => (
  <React.Fragment>
    <Header />
    <section className="breadcrumb-section">
      <div className="container-fluid all-section">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-1 order-2 mt-5 mt-lg-0">
            <div className="inner-head">
              <div className="heading">
                <h2>Product1-Blind</h2>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-lg-2 order-1 pl-md-0 pr-md-0 pl-sm-0 pr-sm-0 pl-0 pr-0">
            <div className="bread-img">
              <StaticImage
                src="../images/find.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="base-section">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="base-content">
              <div className="heading">
                <h2>The Base Palette</h2>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Tempore iure accusamus officiis non dolore aliquam reiciendis
                  assumenda architecto fugit! Suscipit obcaecati esse cupiditate
                  autem explicabo dicta eum itaque. Ipsam, accusamus?
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="base-img">
              <StaticImage
                src="../images/base1.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="inner-slider">
      <div className="container-fluid">
        <div className="row">
          <div id="inner" className="owl-carousel">
            <div className="col-lg-12">
              <StaticImage
                src="../images/inner1a.png"
                alt=""
                className="img-fluid shadow"
              />
            </div>

            <div className="col-lg-12">
              <StaticImage
                src="../images/inner2a.png"
                alt=""
                className="img-fluid shadow"
              />
            </div>

            <div className="col-lg-12">
              <StaticImage
                src="../images/inner3a.png"
                alt=""
                className="img-fluid shadow"
              />
            </div>

            <div className="col-lg-12">
              <StaticImage
                src="../images/inner4a.png"
                alt=""
                className="img-fluid shadow"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="sub-section">
      <div className="container-fluid">
        <div className="row justify-content-center text-center mb-4">
          <div className="col-xl-6 col-lg-10 col-md-12">
            <div className="sub-content">
              <div className="heading">
                <h1>Perfect sub styles</h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo
                  aliquam, Lorem ipsum dolor sit amet consectetur adipisicing
                  elit. Quo aliquam, repudiandae laudantium.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center mt-5 mb-5">
          <div className="col-lg-6">
            <div className="cos-content">
              <div className="heading">
                <h2>Base</h2>
                <p>
                  Lorem, ipsum dolor sit amet consectetur dolore adipisicing
                  elit. Tempore iure accusamus officiis non dolore aliquam?
                </p>
                <a href="/#" className="btn btn-black mt-4">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="base-img">
              <StaticImage
                src="../images/base2.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="row align-items-center mt-5 mb-5">
          <div className="col-lg-6 order-lg-1 order-2">
            <div className="base-img">
              <StaticImage
                src="../images/base3.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-6 order-lg-2 order-1">
            <div className="cos-content">
              <div className="heading">
                <h2>Base</h2>
                <p>
                  Lorem, ipsum dolor sit amet consectetur dolore adipisicing
                  elit. Tempore iure accusamus officiis non dolore aliquam?
                </p>
                <a href="/#" className="btn btn-black mt-4">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center mt-5 mb-5">
          <div className="col-lg-6">
            <div className="cos-content">
              <div className="heading">
                <h2>Base</h2>
                <p>
                  Lorem, ipsum dolor sit amet consectetur dolore adipisicing
                  elit. Tempore iure accusamus officiis non dolore aliquam?
                </p>
                <a href="/#" className="btn btn-black mt-4">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="base-img">
              <StaticImage
                src="../images/base4.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </React.Fragment>
);
export default Styledetails;
